import React from 'react'
import "./ReserveTaxiHero.css"
import { useState, useEffect } from 'react';
import LandingImage from "./LandingImage.webp"

const ReserveTaxi = () => {

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='r-hero'>
            <div className='r-hero-section'>
                <div>
                    <h1 className={`r-hero-title ${animation ? 'animation' : ''}`}>Rezervo Taxi</h1>
                    <p className={`r-hero-paragraph ${animation ? 'animation' : ''}`}>Taxi Beka</p>
                </div>
            </div>
            <img className='r-hero-image' src={LandingImage} alt="" />
        </div>
    )
}

export default ReserveTaxi