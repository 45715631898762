import React from 'react'
import HeroEN from "../components/HomeComponentsEN/HomeHeroEN/HomeHeroEN"
import ServicesSectionEN from '../components/HomeComponentsEN/ServicesSectionEN/ServicesSectionEN'
import AboutUsSectionEN from "../components/HomeComponentsEN/AboutUsSectionEN/AboutUsSectionEN"
import CallTaxiSectionEN from '../components/HomeComponentsEN/CallTaxiSectionEN/CallTaxiSectionEN'
import GalleryEN from '../components/HomeComponentsEN/GalleryEN/GalleryEN'

const Home = () => {
  return (
    <>
      <HeroEN />
      <ServicesSectionEN />
      <AboutUsSectionEN />
      <CallTaxiSectionEN />
      <GalleryEN />
    </>
  )
}

export default Home