import React from 'react'
import { Route, Routes, Router, Link } from 'react-router-dom'
import Home from './pages/Home'
import HomeEN from './pages/HomeEN'
import AboutUs from './pages/AboutUs'
import AboutUsEN from "./pages/AboutUsEN"
import Services from './pages/Services'
import ServicesEN from './pages/ServicesEN'
import ApplyWork from './pages/ApplyWork'
import Reserve from './pages/Reserve'
import Navbar from './components/Navbar/Navbar.jsx'
import Footer from './components/Footer/Footer.jsx'
import PageNotFound from './pages/PageNotFound.jsx'
import MessageSuccess from './pages/MessageSuccess'
import Loader from './components/Loader/Loader'
import { useState, useEffect } from 'react'

import LandingImageHome from "./components/HomeComponents/HomeHero/LandingImage.webp"
import LandingImageAbout from "./components/AboutUsComponents/AboutUsHero/LandingImage.webp"
import LandingImageServices from "./components/ServicesComponent/ServicesHero/LandingImage.webp"
import LandingImageApply from "./components/ApplyWorkComponent/ApplyWorkHero/LandingImage.webp"
import LandingImageReserve from "./components/ReserveTaxiComponent/ReserveTaxiHero/LandingImage.webp"
import LandingImageFound from "./components/PageNotFoundComponent/PageNotFoundHero.jsx/LandingImage.webp"
import LandingImageMessage from "./components/MessageSucessComponent/LandingImage.webp"

const App = () => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500)
  })

  return (
    <>
      {loading ? <Loader /> :

        <div>

          <Link rel="preload" as="image" href={LandingImageHome && LandingImageAbout && LandingImageApply && LandingImageFound && LandingImageMessage && LandingImageReserve && LandingImageServices} />


          <Navbar />

          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/en' element={<HomeEN />} />
            <Route path='/per-ne' element={<AboutUs />} />
            <Route path='/en/about-us' element={<AboutUsEN />} />
            <Route path='/sherbimet' element={<Services />} />
            <Route path='/en/services' element={<ServicesEN />} />
            <Route path='/punesohu' element={<ApplyWork />} />
            <Route path='/rezervo' element={<Reserve />} />
            <Route path='*' element={<PageNotFound />} />
            <Route path='/mesazhi-sukses' element={<MessageSuccess />} />
          </Routes>

          <Footer />
        </div>
      }
    </>
  )
}

export default App