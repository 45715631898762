import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./PageNotFoundHero.css"
import { useState, useEffect } from 'react';
import LandingImage from "./LandingImage.webp"

const PageNotFoundHero = () => {

    const navigate = useNavigate();

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='nf-hero'>
            <div className='nf-hero-section'>
                <div>
                    <h1 className={`nf-hero-title ${animation ? 'animation' : ''}`}>Error 404</h1>
                    <p className={`nf-hero-paragraph ${animation ? 'animation' : ''}`}>Faqja nuk u gjet!</p>
                    <Link className={`nf-back-button ${animation ? 'animation' : ''}`} onClick={() => navigate(-1)}>
                        Kthehu Prapa
                    </Link>
                </div>
            </div>
            <img className='nf-hero-image' src={LandingImage} alt="" />
        </div>
    )
}

export default PageNotFoundHero