import React from 'react'
import Touran from "./Touran.webp"
import "./ServicesSection.css"
import Tick from "./Tick.webp"
import Vw from "./VW.webp"
import Driver from "./Driver.webp"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const ServicesSection = () => {
  return (
    <div className='home-section'>
      <div className='img-div'>
        <img data-aos="fade-up" data-aos-duration="1000" src={Touran} alt="" />
      </div>
      <div className="paragraph-div">
        <h1 data-aos="fade-up" data-aos-duration="1000">Shërbimet Tona</h1>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Tick} alt="" />
          <p>Ne gjithmonë i marrim klientët tonë në kohë, në dispozicion 24/7.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Vw} alt="" />
          <p> Flota jonë përbëhet nga VW ID.4 elegant dhe Touran, të kondicionuar me ajër të pastër, freski, elektrik dhe mbi të gjitha komod!</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Driver} alt="" />
          <p>Në kontrollin e vazhdueshëm të bazistëve, shoferët do t'ju sigurojnë që ju të arrini destinacionin tuaj në kohë dhe mbi të gjitha me siguri!</p>
        </div>
        <Link data-aos="fade-up" data-aos-duration="1000" to="/sherbimet">
          <span>Më shumë.</span>
        </Link>
      </div>
    </div>
  )
}

export default ServicesSection