import React from 'react'
import "./JoinTeam.css"
import Foto from "./Foto.webp"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const JoinTeam = () => {
    return (
        <div className='jt-section'>
            <div className='jt-title-section'>
                <div className="jt-title">
                    <h1 data-aos="fade-up" data-aos-duration="1000">Bashkohu</h1>
                    <p data-aos="fade-up" data-aos-duration="1000">Bashkohu edhe ti me ekipin më të madh të taksive në Pejë! </p>
                </div>
                <div className="jt-link" data-aos="fade-up" data-aos-duration="1000">
                    <Link to="/punesohu">
                        Bashkohu
                    </Link>
                </div>
            </div>
            <div className="jt-image">
                <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
            </div>
            <div className="jt-jobs">
                <div className="jobs" data-aos="fade-up" data-aos-duration="1500">
                    <h1>Vozitës</h1>
                    <p>Bashkangjitu ekipës sonë si vozitës dhe shfaq aftësitë njohëse të rrugëve të Pejës.</p>
                    <Link to="/punesohu">
                        Bashkohu
                    </Link>
                </div>
                <div className="jobs" data-aos="fade-up" data-aos-duration="1500">
                    <h1>Bazistë</h1>
                    <p>Bashkangjitu ekipës sonë si bazistë dhe shfaq aftësitë tuaja menaxhuese e drejtuese.</p>
                    <Link to="/punesohu">
                        Bashkohu
                    </Link>
                </div>
                <div className="jobs" data-aos="fade-up" data-aos-duration="1500">
                    <h1>Mekanikë</h1>
                    <p data-aos="fade-up" data-aos-duration="1000">Bashkangjitu ekipës sonë si mekanikë dhe shfaq dashurinë e njohuritë tua rreth veturave.</p>
                    <Link to="/punesohu">
                        Bashkohu
                    </Link>
                </div>
                <div className="jobs" data-aos="fade-up" data-aos-duration="1500">
                    <h1>Auto-Larje</h1>
                    <p>Bashkangjitu ekipës sonë si auto-larës dhe trego aftësitë tuaja detajuese në pastrim.</p>
                    <Link to="/punesohu">
                        Bashkohu
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default JoinTeam