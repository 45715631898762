import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "./MessageSucessHero.css"
import LandingImage from "./LandingImage.webp"

const MessageSuccessHero = () => {

    const navigate = useNavigate();

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='ms-hero'>
            <div className='ms-hero-section'>
                <div>
                    <h1 className={`ms-hero-title ${animation ? 'animation' : ''}`}>Sukses</h1>
                    <p className={`ms-hero-paragraph ${animation ? 'animation' : ''}`}>Mesazhi u dërgua me sukses. Ne do ju lajmërojmë sapo të kemi mundësinë!</p>
                    <Link className={`ms-back-button ${animation ? 'animation' : ''}`} onClick={() => navigate(-1)}>
                        Kthehu Prapa
                    </Link>
                </div>
            </div>
            <img className='ms-hero-image' src={LandingImage} alt="" />
        </div>
    )
}

export default MessageSuccessHero