import React from 'react'
import "./AboutUsSectionEN.css"
import Foto from "./Foto.png"
import Foto1 from "./Foto1.png"
import Foto2 from "./Foto2.png"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const AboutUsSection = () => {
    return (
        <div className="about-section">
            <div className='section-1'>
                <div className="title-div">
                    <h1 data-aos="fade-up" data-aos-duration="1000">About Us</h1>
                    <p data-aos="fade-up" data-aos-duration="1000">With over 18 years of experience, Taxi Beka is a leader in
                        taxi industry with a capacity of over 20 luxury cars. With a large extension on
                        throughout the region of Peja, we offer you safe trips inside
                        and outside of Kosovo, under the operation of licensed taxi drivers with great driving experience.
                    </p>
                    <Link data-aos="fade-up" data-aos-duration="1000" to="/en/about-us">
                        <span>More.</span>
                    </Link>
                </div>
                <div className="image-section" data-aos="fade-up" data-aos-duration="1000">
                    <img src={Foto} alt="" />
                    <h1>Fully Electric</h1>
                </div>
            </div>
            <div className='section-2' data-aos="fade-up" data-aos-duration="1000">
                <div className="image-1-section">
                    <img src={Foto1} alt="" />
                    <h1>Safety First</h1>
                </div>
                <div className="image-2-section" data-aos="fade-up" data-aos-duration="1000">
                    <img src={Foto2} alt="" />
                    <h1>Always Ready</h1>
                </div>
            </div>
        </div>
    )
}

export default AboutUsSection