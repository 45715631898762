import React from 'react'
import Hero from '../components/AboutUsComponents/AboutUsHero/AboutUsHero'
import LeaderSection from '../components/AboutUsComponents/LeaderSection/LeaderSection'
import FleetSection from '../components/AboutUsComponents/FleetSection/FleetSection'
import PrinciplesAndClients from '../components/AboutUsComponents/PrinciplesAndClients/PrinciplesAndClients'

import { useEffect } from 'react'

const AboutUs = () => {

  useEffect(() => {
    document.title = 'Rreth Taxi Beka';
  }, []);

  return (
    <>
      <Hero />
      <LeaderSection />
      <FleetSection />
      <PrinciplesAndClients />
    </>
  )
}

export default AboutUs