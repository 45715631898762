import React from 'react'
import "./LeaderSection.css"
import Foto from "./Foto.webp"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const LeaderSection = () => {
  return (
    <div className='leader-section'>
      <div className='l-img-div'>
        <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
      </div>
      <div className="l-paragraph-div">
        <h4 data-aos="fade-up" data-aos-duration="1000">Rreth Nesh</h4>
        <h1 data-aos="fade-up" data-aos-duration="1000">Lider në industrinë e <span>taksive!</span></h1>
        <p data-aos="fade-up" data-aos-duration="1000">Një udhëtim i madh fillon me një lëvizje nëpër rrugë dhe vazhdon drejt qëllimeve dhe ëndrrave. Një varg momentesh që i afron njerëzit dhe i bën distancat më të vogla. Çfarëdo udhëtimi që kërkoni, me pak klikime ju ndihmojmë të arrini në mënyrë të sigurt, në kohë dhe rahatshëm. Sepse nën teknologjinë tonë, ne nuk ofrojmë vetëm udhëtime, por ndërtojmë lidhje dhe një udhëtim për të treguar!</p>
      </div>
    </div>
  )
}

export default LeaderSection