import React from 'react'
import ServicesHeroEN from "../components/ServicesComponentEN/ServicesHero/ServicesHeroEN"
import GuaranteedSafetyEN from '../components/ServicesComponentEN/GuaranteedSafetyEN/GuaranteedSafetyEN'
import EverythingGlanceEN from '../components/ServicesComponentEN/EverythingGlanceEN/EverythingGlanceEN'
import QuestionsEN from '../components/ServicesComponentEN/QuestionsEN/QuestionsEN'
import { useEffect } from 'react'

const Services = () => {

    useEffect(() => {
        document.title = 'Taxi Beka Services';
    }, []);

    return (
        <>
            <ServicesHeroEN />
            <GuaranteedSafetyEN />
            <EverythingGlanceEN />
            <QuestionsEN />
        </>
    )
}

export default Services