import React from 'react'
import Foto from "./Foto.webp"
import Foto1 from "./Foto1.webp"
import "./EverythingGlanceEN.css"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const EverythingGlance = () => {
    return (
        <div className='eg-section'>
            <h1 data-aos="fade-up" data-aos-duration="1000">Everything at Glance</h1>
            <div className="eg-box-section">
                <div className='eg-boxes'>
                    <div className="eg-box-text" data-aos="fade-up" data-aos-duration="1000">
                        <div data-aos="fade-up" data-aos-duration="2000">
                            <h3>18 +</h3>
                            <p>Years of experience</p>
                        </div>
                    </div>
                    <div className="eg-box-text" data-aos="fade-up" data-aos-duration="1000">
                        <div data-aos="fade-up" data-aos-duration="2000">
                            <h3>50 +</h3>
                            <p>Workers</p>
                        </div>
                    </div>
                </div>
                <div className="eg-box-image">
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
                </div>
            </div>
            <div className="eg-box-section eg-top">
                <div className="eg-box-image">
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto1} alt="" />
                </div>
                <div className="eg-boxes" data-aos="fade-up" data-aos-duration="1000">
                    <div className="eg-box-text">
                        <div data-aos="fade-up" data-aos-duration="2000">
                            <h3>20 +</h3>
                            <p>Vehicles</p>
                        </div>
                    </div>
                    <div className="eg-box-text" data-aos="fade-up" data-aos-duration="1000">
                        <div data-aos="fade-up" data-aos-duration="2000">
                            <h3>30k +</h3>
                            <p>Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EverythingGlance