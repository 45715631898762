import React from 'react'
import "./Navbar.css"
import Logo from "./LogoNavbar.webp"
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Navbar = () => {

    const [sherbimet, setSherbimet] = useState();
    const [perne, setPerne] = useState();
    const [punesohu, setPunesohu] = useState();
    const [reserve, setReserve] = useState();
    const [services, setServices] = useState();
    const [aboutUs, setAboutUs] = useState();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/en') {
            setSherbimet('Services');
            setPerne('About Us');
            setPunesohu('Work with us');
            setReserve('Reserve a Taxi');
            setServices('/en/services');
            setAboutUs('/en/about-us');
        } else if (location.pathname === '/en/services') {
            setSherbimet('Services');
            setPerne('About Us');
            setPunesohu('Work with us');
            setReserve('Reserve a Taxi');
            setServices('/en/services');
            setAboutUs('/en/about-us');
        } else if (location.pathname === '/en/about-us') {
            setSherbimet('Services')
            setPerne('About Us')
            setPunesohu('Work with us');
            setReserve('Reserve a Taxi');
            setServices('/en/services');
            setAboutUs('/en/about-us');
        } else {
            setSherbimet("Shërbimet")
            setPerne("Për ne")
            setPunesohu('Punësohu');
            setReserve('Rezervo Taxi');
            setServices('/sherbimet');
            setAboutUs('/per-ne');
        }
    }, [location]);

    const toggleMenu = () => document.body.classList.toggle("open");

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground);
    })

    return (
        <nav className={navbar ? "navbar active" : "navbar"}>
            <div className='nav-blur'></div>
            <button onClick={toggleMenu} className="burger"></button>
            <Link to="/">
                <img loading="lazy" className='logo' src={Logo} alt="" />
            </Link>
            <div className="links">
                <div className="link">
                    <Link to={services} onClick={() => toggleMenu()} className='button'>
                        {sherbimet}
                    </Link>
                </div>
                <div className="link">
                    <Link to={aboutUs} onClick={toggleMenu} className="button">
                        {perne}
                    </Link>
                </div>
                <div className="link">
                    <Link to="/punesohu" onClick={toggleMenu} className="button">
                        {punesohu}
                    </Link>
                </div>
                <div className="link-reserve">
                    <Link to="/rezervo" onClick={toggleMenu} className="button reserve">
                        {reserve}
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default Navbar