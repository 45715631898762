import React from 'react'
import "../AboutUsHero/AboutUsHero.css"
import { useState, useEffect } from 'react';
import LandingImage from "./LandingImage.webp"

const Hero = () => {

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='au-hero'>
            <div className='au-hero-section'>
                <div>
                    <h1 className={`au-hero-title ${animation ? 'animation' : ''}`}>Për Ne</h1>
                    <p className={`au-hero-paragraph ${animation ? 'animation' : ''}`}>Taxi Beka</p>
                </div>
            </div>
            <img className='au-hero-image' src={LandingImage} alt="" />
        </div>
    )
}

export default Hero