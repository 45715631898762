import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import "./GalleryEN.css"
import Foto from "./SliderFoto.webp"
import Foto1 from "./SliderFoto1.webp"
import Foto2 from "./SliderFoto2.webp"
import Foto3 from "./SliderFoto3.webp"
import Foto4 from "./SliderFoto4.webp"
import Foto5 from "./SliderFoto5.webp"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const Gallery = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className='g-section'>
            <h1 data-aos="fade-up" data-aos-duration="1500">Gallery</h1>
            <div data-aos="fade-up" data-aos-duration="1500">
                <Slider {...settings} className="g-slider" style={{ outline: "none" }}>
                    <div>
                        <img src={Foto} alt="" />
                    </div>
                    <div>
                        <img src={Foto1} alt="" />
                    </div>
                    <div>
                        <img src={Foto2} alt="" />
                    </div>
                    <div>
                        <img src={Foto3} alt="" />
                    </div>
                    <div>
                        <img src={Foto4} alt="" />
                    </div>
                    <div>
                        <img src={Foto5} alt="" />
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Gallery