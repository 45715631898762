import React from 'react'
import "./GuaranteedSafetyEN.css"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const GuaranteedSafety = () => {
    return (
        <div className='gs-section'>
            <h1 data-aos="fade-up" data-aos-duration="1000">Guaranteed Safety</h1>
            <p data-aos="fade-up" data-aos-duration="1000">For many years we have strived to be the number one choice for taxis throughout Peja and are able to take our customers to any destination. Using the latest GPS-Dispatching technology, we are able to track our vehicles at all times. Our advanced computer systems keep us at the forefront of efficient transportation services throughout Kosovo.</p>
        </div>
    )
}

export default GuaranteedSafety