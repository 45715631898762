import React from 'react'
import "./ApplyWork.css"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file
import { useState } from 'react';

AOS.init();

const ApplyWork = () => {

  const [selectedOptions, setSelectedOptions] = useState({});
  const options = ["Kategoria A", "Kategoria B", "Kategoria C", "Kategoria D", "Kategoria E"];

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedOptions({ ...selectedOptions, [name]: checked });
  };

  return (
    <div className='aw-section'>
      <h1 data-aos="fade-up" data-aos-duration="1000">Apliko për punë</h1>
      <p data-aos="fade-up" data-aos-duration="1000">Në formularin e dhënë më poshtë plotësoni të gjitha kërkesat dhe pastaj shtypni butonin
        <strong> "Dërgo"</strong>. Nëse nuk i keni plotësuar të gjitha kërkesat ose nuk i keni shkruar si
        duhet, atëherë formulari juaj nuk do dërgohet. Nëse i keni plotësuar mirë dhe ju shfaqet që
        formulari nuk është dërguar atëherë provoni përsëri. Pasi t'a keni dërguar formularin, ai do arrijë
        në zyret tona dhe do ju kontaktojmë brenda një periudhe të shkurtë!</p>

      <div data-aos="fade-up" data-aos-duration="1000" className="aw-questions">
        <form action="https://formsubmit.co/taxibeka5@gmail.com" method="POST">
          <input type="hidden" name="_next" value="https://taxibeka.com/mesazhi-sukses" />
          <input type="hidden" name="_subject" value="Aplikim i ri për punë!" />

          <p className='kolonat'>Kolonat me * duhet plotësuar!</p>

          <div class="aw-question-radio sm-tp">
            <h3>* Pozita:</h3>
            <p>
              <input type="radio" id="vozites" name="Pozita" value="Vozitës" required />
              <label for="vozites">Vozitës</label>
            </p>
            <p>
              <input type="radio" id="baziste" name="Pozita" value="Bazistë" required />
              <label for="baziste">Bazistë</label>
            </p>
            <p>
              <input type="radio" id="mekanik" name="Pozita" value="Mekanik" required />
              <label for="mekanik">Mekanik</label>
            </p>
            <p>
              <input type="radio" id="autolarje" name="Pozita" value="Auto-Larje" required />
              <label for="autolarje">Auto Larje</label>
            </p>
          </div>

          <div className="aw-question-input">
            <label for="emridhembiemri">* Emri dhe Mbiemri:</label>
            <input type="text" id="emridhembiemri" required minLength={5} autoComplete="off" placeholder='Filan Fisteku' name="Emri-dhe-Mbiemri" />
          </div>

          <div className="aw-question-input">
            <label for="ditelindja">* Ditëlindja:</label>
            <input type="text" id="ditelindja" required minLength={5} autoComplete="off" placeholder='11.01.2001' name="Ditëlindja" />
          </div>

          <div class="aw-question-radio mt">
            <h3>* Gjinia:</h3>
            <p>
              <input type="radio" id="mashkull" name="Gjinia" value="Mashkull" required />
              <label for="mashkull">Mashkull</label>
            </p>
            <p>
              <input type="radio" id="femer" name="Gjinia" value="Femër" required />
              <label for="femer">Femër</label>
            </p>
          </div>

          <div className="aw-question-input">
            <label for="vendilindjes">* Vendi dhe komuna lindjes:</label>
            <input type="text" id="vendilindjes" required minLength={3} autoComplete="off" placeholder='Pejë' name="Vendi-lindjes" />
          </div>

          <div className="aw-question-input">
            <label for="numrileternjoftimit">* Numri i letërnjoftimit:</label>
            <input type="number" id="numrileternjoftimit" required minLength={10} maxLength={10} autoComplete="off" name='Numri-leternjoftimit' />
          </div>

          <div class="aw-question-radio mt">
            <h3>* Statusi:</h3>
            <p>
              <input type="radio" id="martuar" name="Statusi" value="Martuar" required />
              <label for="martuar">Martuar/e</label>
            </p>
            <p>
              <input type="radio" id="beqar" name="Statusi" value="Beqar" required />
              <label for="beqar">Beqar/e</label>
            </p>
          </div>

          <div className="aw-question-input">
            <label for="patentshoferiVL">Patent shoferi (viti i lëshimit):</label>
            <input type="number" id="patentshoferiVL" autoComplete="off" name='Patent-shoferi-viti-leshimit' />
          </div>

          <div class="aw-question-radio mt">
            <h3 className='patent-h3'>Patent shoferi (kategoria):</h3>

            {options.map((options, index) => (
              <div className='form-group' key={index}>
                <input
                  type="checkbox"
                  name={options}
                  checked={selectedOptions[options] || false}
                  onChange={handleCheckboxChange}
                  id={options}
                />
                <label htmlFor={options}>{options}</label>
              </div>
            ))}

            {/* <div class="form-group">
              <input type="checkbox" name='Patent-shoferi-kategoria' id="A" checked={isChecked} onChange={handleCheckboxChange} />
              <label for="A">A</label>
            </div>

            <div class="form-group">
              <input type="checkbox" name='Patent-shoferi-kategoria' id="B" checked={isChecked} onChange={handleCheckboxChange} />
              <label for="B">B</label>
            </div>

            <div class="form-group">
              <input type="checkbox" name='Patent-shoferi-kategoria' id="C" checked={isChecked} onChange={handleCheckboxChange} />
              <label for="C">C</label>
            </div>

            <div class="form-group">
              <input type="checkbox" name='Patent-shoferi-kategoria' id="D" checked={isChecked} onChange={handleCheckboxChange} />
              <label for="D">D</label>
            </div>

            <div class="form-group">
              <input type="checkbox" name='Patent-shoferi-kategoria' id="E" checked={isChecked} onChange={handleCheckboxChange} />
              <label for="E">E</label>
            </div> */}

          </div>

          {/* <div className="aw-question-input">
            <label for="patentshoferiK">Patent shoferi (kategoria):</label>
            <input type="text" id="patentshoferiK" autoComplete="off" name='Patent-shoferi-kategoria' />
          </div> */}

          <div className="aw-question-input">
            <label for="adresa">* Adresa:</label>
            <input type="text" id="adresa" required autoComplete="off" name='Adresa' placeholder='Adresa e tanishme' />
          </div>

          <div className="aw-question-input">
            <label for="numritelefonit">* Numri i telefonit:</label>
            <input type="text" id="numritelefonit" required autoComplete="off" name='Numri-telefonit' />
          </div>

          <div className="aw-question-input">
            <label for="email">Email:</label>
            <input type="email" id="email" autoComplete="off" name='Email' placeholder='filan@email.com' />
          </div>

          <div className="aw-question-input">
            <label for="shkollimi">Shkollimi:</label>
            <input type="text" id="shkollimi" autoComplete="off" name='Shkollimi' placeholder='Shkollimi' />
          </div>

          <div className="aw-question-input">
            <label for="njohjagjuheve">Njohja e gjuhëve:</label>
            <input type="text" id="njohjagjuheve" autoComplete="off" name='Njohja-gjuhëve' placeholder='Anglisht, Gjermanisht' />
          </div>

          <div class="aw-question-radio mt">
            <h3>Keni qenë ndonjëherë të dënuar me 6+ muaj:</h3>
            <p>
              <input type="radio" id="po" name="Dënimi" value="Po" />
              <label for="po">Po</label>
            </p>
            <p>
              <input type="radio" id="jo" name="Dënimi" value="Jo" />
              <label for="jo">Jo</label>
            </p>
          </div>

          <div className="aw-question-input">
            <label for="pervojapunes">* Përvoja e punës:</label>
            <textarea name="Përvoja-punes" id="pervojapunes" cols="30" required rows="5" placeholder='Kam punuar tek...'></textarea>
          </div>

          <button type='submit' id="Dergo">Dërgo</button>

        </form>
      </div>
    </div>
  )
}

export default ApplyWork