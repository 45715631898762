import React from 'react'
import "./AboutUsSection.css"
import Foto from "./Foto.png"
import Foto1 from "./Foto1.png"
import Foto2 from "./Foto2.png"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const AboutUsSection = () => {
    return (
        <div className="about-section">
            <div className='section-1'>
                <div className="title-div">
                    <h1 data-aos="fade-up" data-aos-duration="1000">Rreth Nesh</h1>
                    <p data-aos="fade-up" data-aos-duration="1000"> Me një përvojë mbi 18-vjeçare, Taxi Beka është lider në
                        indrustrinë e taksive me një kapacitet të mbi 20 makinave luksoze. Me një shtrirje të madhe mbi
                        gjithë rajonin e Pejës ne ju ofrojmë udhëtime të sigurta brenda
                        dhe jashtë Kosovës, nën operimin e taksistëve të licensuar dhe me përvojë të madhe në vozitje.
                    </p>
                    <Link data-aos="fade-up" data-aos-duration="1000" to="/per-ne">
                        <span>Më shumë.</span>
                    </Link>
                </div>
                <div className="image-section" data-aos="fade-up" data-aos-duration="1000">
                    <img src={Foto} alt="" />
                    <h1>100% Elektrik</h1>
                </div>
            </div>
            <div className='section-2' data-aos="fade-up" data-aos-duration="1000">
                <div className="image-1-section">
                    <img src={Foto1} alt="" />
                    <h1>Siguria e Para</h1>
                </div>
                <div className="image-2-section" data-aos="fade-up" data-aos-duration="1000">
                    <img src={Foto2} alt="" />
                    <h1>Gjithmonë Gati</h1>
                </div>
            </div>
        </div>
    )
}

export default AboutUsSection