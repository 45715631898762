import React from 'react'
import "./GuaranteedSafety.css"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const GuaranteedSafety = () => {
    return (
        <div className='gs-section'>
            <h1 data-aos="fade-up" data-aos-duration="1000">Siguri e Garantuar</h1>
            <p data-aos="fade-up" data-aos-duration="1000">Për shumë vite ne jemi përpjekur të jemi zgjedhja numër një për taksitë në të gjithë Pejën dhe jemi në gjendje t'i çojmë klientët tanë në çdo destinacion. Duke përdorur teknologjinë më të fundit GPS-Dispatching, ne jemi në gjendje të gjurmojmë automjetet tona në çdo kohë. Sistemet tona të avancuara kompjuterike na mbajnë në krye të shërbimeve efikase të transportit në mbarë Kosovën.</p>
        </div>
    )
}

export default GuaranteedSafety