import React from 'react'
import ReserveTaxiHero from '../components/ReserveTaxiComponent/ReserveTaxiHero/ReserveTaxiHero'
import ReserveTaxiForm from '../components/ReserveTaxiComponent/ReserveTaxiForm/ReserveTaxiForm'

const Reserve = () => {
  return (
    <>
      <ReserveTaxiHero />
      <ReserveTaxiForm />
    </>
  )
}

export default Reserve