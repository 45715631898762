import React from 'react'
import "./HomeHero.css"
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LandingImage from "./LandingImage.webp"

const Hero = () => {

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='home-hero'>
            <div className='home-hero-section'>
                <div>
                    <h1 className={`home-hero-title ${animation ? 'animation' : ''}`}>Shpejtë, <br />
                        Komod, <br />
                        Sigurtë, <span className='eco'>ECO</span>
                    </h1>
                    <a className={`home-hero-link ${animation ? 'animation' : ''}`} href="tel:080020100">Thirre Taxin pa pare...</a>
                </div>
                <div class="arrowCta"></div>
            </div>
            <img className='hero-image' src={LandingImage} alt="" />
            <div className='cl-section'>
                <Link to="/" className="sq">
                    SQ
                </Link>
                <Link to="/en" className="en">
                    EN
                </Link>
            </div>
        </div>
    )
}

export default Hero