import React from 'react'
import "./ServicesHero.css"
import { useState, useEffect } from 'react';
import LandingImage from "./LandingImage.webp"

const ServicesHero = () => {

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='s-hero'>
            <div className='s-hero-section'>
                <div>
                    <h1 className={`s-hero-title ${animation ? 'animation' : ''}`}>Shërbimet Tona</h1>
                    <p className={`s-hero-paragraph ${animation ? 'animation' : ''}`}>Taxi Beka</p>
                </div>
            </div>
            <img className='s-hero-image' src={LandingImage} alt="" />
        </div>
    )
}

export default ServicesHero