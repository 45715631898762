import React from 'react'
import Hero from "../components/ServicesComponent/ServicesHero/ServicesHero"
import GuaranteedSafety from "../components/ServicesComponent/GuaranteedSafety/GuaranteedSafety"
import EverythingGlance from '../components/ServicesComponent/EverythingGlance/EverythingGlance'
import Questions from '../components/ServicesComponent/Questions/Questions'
import { useEffect } from 'react'

const Services = () => {

    useEffect(() => {
        document.title = 'Shërbimet e Taxi Beka';
    }, []);

    return (
        <>
            <Hero />
            <GuaranteedSafety />
            <EverythingGlance />
            <Questions />
        </>
    )
}

export default Services