import React from 'react'
import "./LeaderSectionEN.css"
import Foto from "./Foto.webp"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const LeaderSection = () => {
  return (
    <div className='leader-section'>
      <div className='l-img-div'>
        <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
      </div>
      <div className="l-paragraph-div">
        <h4 data-aos="fade-up" data-aos-duration="1000">About US</h4>
        <h1 data-aos="fade-up" data-aos-duration="1000">Leader in the <span>taxi</span> industry!</h1>
        <p data-aos="fade-up" data-aos-duration="1000">A great journey begins with a drive down the road and continues towards goals and dreams. A series of moments that bring people closer and make distances smaller. Whatever journey you are looking for, with a few clicks we help you get there safely, on time and comfortably. Because under our technology, we don't just offer trips, we build connections and a trip to show!</p>
      </div>
    </div>
  )
}

export default LeaderSection