import React from 'react'
import Hero from '../components/HomeComponents/HomeHero/HomeHero'
import ServicesSection from '../components/HomeComponents/ServicesSection/ServicesSection.jsx'
import AboutUsSection from '../components/HomeComponents/AboutUsSection/AboutUsSection'
import CallTaxiSection from '../components/HomeComponents/CallTaxiSection/CallTaxiSection'
import JoinTeam from '../components/HomeComponents/JoinTeamSection/JoinTeam'
import Gallery from '../components/HomeComponents/Gallery/Gallery'

const Home = () => {
  return (
    <>
      <Hero />
      <ServicesSection />
      <AboutUsSection />
      <CallTaxiSection />
      <JoinTeam />
      <Gallery />
    </>
  )
}

export default Home