import React from 'react'
import ApplyWorkComponent from '../components/ApplyWorkComponent/ApplyWorkComponent/ApplyWork'
import ApplyWorkHero from '../components/ApplyWorkComponent/ApplyWorkHero/ApplyWorkHero'
import { useEffect } from 'react'

const ApplyWork = () => {

    useEffect(() => {
        document.title = 'Punësohu në Taxi Beka';
    }, []);

    return (
        <>
            <ApplyWorkHero />
            <ApplyWorkComponent />
        </>
    )
}

export default ApplyWork