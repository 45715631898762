import React from 'react'
import "./QuestionsEN.css"
import Foto from "./Foto.png"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const Questions = () => {
    return (
        <div className='q-section'>
            <h1>Questions</h1>
            <div className="q-div">
                <div className="q-accordion-section">
                    <div class="tab" data-aos="fade-up" data-aos-duration="1000">
                        <input id="tab-one" type="checkbox" name="tabs" />
                        <label for="tab-one">What city do you operate in?</label>
                        <div class="tab-content">
                            <p>Taxi Beka's goal from the very beginning has been to reach every corner of the city of Peja. Over time, we have managed to complete trips all around Balkan aswell.</p>
                        </div>
                    </div>
                    <div class="tab" data-aos="fade-up" data-aos-duration="1500">
                        <input id="tab-two" type="checkbox" name="tabs" />
                        <label for="tab-two">How long for the taxi to arrive?</label>
                        <div class="tab-content">
                            <p>Based on road limits we speed to get to you, but safety is paramount. With the help of our professional guides, we show you the optimal time within which the paddle is available to you.</p>
                        </div>
                    </div>
                    <div class="tab" data-aos="fade-up" data-aos-duration="2000">
                        <input id="tab-three" type="checkbox" name="tabs" />
                        <label for="tab-three">Why travel with you?</label>
                        <div class="tab-content">
                            <p>The experience we have gained during 18 years of work made us the primary solution when it comes to taxi services. Safety, comfort and the silence in travel conditions make us unique. As always, experience makes the difference.</p>
                        </div>
                    </div>
                </div>
                <div className="q-img-section">
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Questions