import React from 'react'
import "./CallTaxiSectionEN.css"
import Tourani from "./Tourani.webp"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const CallTaxiSection = () => {
    return (
        <div className='ct-section' data-aos="fade-up" data-aos-duration="1000">
            <div className='ct-image-section'>
                <img data-aos="fade-up" data-aos-duration="2000" src={Tourani} alt="" />
            </div>
            <div className='ct-text-section'>
                <h1 data-aos="fade-up" data-aos-duration="2000">Reserve a Taxi</h1>
                <p data-aos="fade-up" data-aos-duration="2000">Reserve a Taxi for your trip while completing the form!</p>
                <Link data-aos="fade-up" data-aos-duration="2000" to="/en/reserve">
                    Reserve
                </Link>
            </div>
            <h1></h1>
        </div>
    )
}

export default CallTaxiSection