import React from 'react'
import HeroEN from "../components/AboutUsComponentsEN/AboutUsComponents/AboutUsHeroEN/AboutUsHeroEN"
import LeaderSectionEN from "../components/AboutUsComponentsEN/AboutUsComponents/LeaderSectionEN/LeaderSectionEN"
import FleetSectionEN from "../components/AboutUsComponentsEN/AboutUsComponents/FleetSectionEN/FleetSectionEN"
import PrinciplesAndClientsEN from '../components/AboutUsComponentsEN/AboutUsComponents/PrinciplesAndClientsEN/PrinciplesAndClientsEN'

import { useEffect } from 'react'

const AboutUs = () => {

  useEffect(() => {
    document.title = 'About Taxi Beka';
  }, []);

  return (
    <>
      <HeroEN />
      <LeaderSectionEN />
      <FleetSectionEN />
      <PrinciplesAndClientsEN />
    </>
  )
}

export default AboutUs