import React from 'react'
import "./FleetSectionEN.css"
import Foto from "./Foto.png"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const FleetSection = () => {
    return (
        <div className='fleet-background'>
            <div className='fleet-section'>
                <div className="f-paragraph-div">
                    <h4 data-aos="fade-up" data-aos-duration="1000">Our Fleet</h4>
                    <h1 data-aos="fade-up" data-aos-duration="1000"><span>Electric</span> ID.4</h1>
                    <p data-aos="fade-up" data-aos-duration="1000">The comfort and safety of our customers always come first here at Taxi Beka. Our fleet consists of stylish, electric and fully air-conditioned ID.4 with fresh air, freshness and above all comfort!</p>
                </div>
                <div className='f-img-div'>
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
                </div>
            </div>
        </div>
    )
}

export default FleetSection