import React from 'react'
import "./FleetSection.css"
import Foto from "./Foto.png"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const FleetSection = () => {
    return (
        <div className='fleet-background'>
            <div className='fleet-section'>
                <div className="f-paragraph-div">
                    <h4 data-aos="fade-up" data-aos-duration="1000">Flota Jonë</h4>
                    <h1 data-aos="fade-up" data-aos-duration="1000">ID.4 <span>elektrik!</span></h1>
                    <p data-aos="fade-up" data-aos-duration="1000">Komoditeti dhe siguria e klientëve tanë vijnë gjithmonë të parat këtu tek Taxi Beka. Flota jonë përbëhet nga ID.4 elegant, elektrik dhe plotësisht të kondicionuar me ajër të pastër, freski dhe mbi të gjitha komoditet!</p>
                </div>
                <div className='f-img-div'>
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
                </div>
            </div>
        </div>
    )
}

export default FleetSection