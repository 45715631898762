import React from 'react'
import MessageSuccessHero from '../components/MessageSucessComponent/MessageSuccessHero'

const MessageSuccess = () => {
  return (
    <>
      <MessageSuccessHero />
    </>
  )
}

export default MessageSuccess