import React from 'react'
import "./PrinciplesAndClientsEN.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const PrinciplesAndClients = () => {
    return (
        <div className='pac-section'>
            <div className="principles-section">
                <h1 data-aos="fade-up" data-aos-duration="1000">Our <span>Principles!</span></h1>
                <div className='principles-div'>
                    <div className='principles'>
                        <h4 data-aos="fade-up" data-aos-duration="2000">1</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Modern Cars</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">2</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Fresh Cars</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">3</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Professional Service</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">4</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Experienced Workers</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">5</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Correct</p>
                    </div>
                </div>
            </div>
            <div className="clients-section">
                <h1 data-aos="fade-up" data-aos-duration="1000">People <span>Talk!</span></h1>
                <div data-aos="fade-up" data-aos-duration="1000">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>1</h3>
                                <p>"Very good and correct! Respect for the work you do"</p>
                                <h5>- Client</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>2</h3>
                                <p>"You are to be praised for all the passengers, congratulations for the work you do"</p>
                                <h5>- Client</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>3</h3>
                                <p>"Greetings from Germany, I came to Kosovo and I am very happy with, you are the best company"</p>
                                <h5>- Client</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>4</h3>
                                <p>"Greetings from Paris. Two months with you were wonderful. I'm often in Kosovo, so hello to everyone, I had a good time with you"</p>
                                <h5>- Client</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>5</h3>
                                <p>"The best taxi in the city of Peja. Respect"</p>
                                <h5>- Client</h5>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default PrinciplesAndClients