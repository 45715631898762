import React from 'react'
import "./HomeHeroEN.css"
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LandingImage from "./LandingImage.webp"

const Hero = () => {

    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        setAnimation(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='home-hero'>
            <div className='home-hero-section'>
                <div>
                    <h1 className={`home-hero-title ${animation ? 'animation' : ''}`}>Faster, <br />
                        Cozier, <br />
                        Safer, <span className='eco'>ECO</span>
                    </h1>
                    <a className={`home-hero-link ${animation ? 'animation' : ''}`} href="tel:080020100">Call us for free...</a>
                </div>
                <div class="arrowCta"></div>
            </div>
            <img className='hero-image' src={LandingImage} alt="" />
            <div className='cl-section'>
                <Link to="/" className="sq-en">
                    SQ
                </Link>
                <Link to="/en" className="en-en">
                    EN
                </Link>
            </div>
        </div>
    )
}

export default Hero