import React from 'react'
import PageNotFoundHero from '../components/PageNotFoundComponent/PageNotFoundHero.jsx/PageNotFoundHero'

const PageNotFound = () => {

    return (
        <>
            <PageNotFoundHero />
        </>
    )
}

export default PageNotFound