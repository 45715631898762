import React from 'react';
import "./Loader.css"
import Logo from "./Logo.webp"

const LoadingPage = () => {
    return (
        <div className="loading-page">
            <img src={Logo} alt="" />
        </div>
    );
};

export default LoadingPage;
