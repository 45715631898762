import React from 'react'
import Touran from "./Touran.webp"
import "./ServicesSectionEN.css"
import Tick from "./Tick.webp"
import Vw from "./VW.webp"
import Driver from "./Driver.webp"
import { Link } from 'react-router-dom'

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const ServicesSection = () => {
  return (
    <div className='home-section'>
      <div className='img-div'>
        <img data-aos="fade-up" data-aos-duration="1000" src={Touran} alt="" />
      </div>
      <div className="paragraph-div">
        <h1 data-aos="fade-up" data-aos-duration="1000">Our Services</h1>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Tick} alt="" />
          <p>We always get our customers on time, available 24/7 and in a secure way.</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Vw} alt="" />
          <p>Our fleet consists of elegant VW ID.4 and Touran, air conditioned with fresh air, freshness, all electric and above all comfort!</p>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className='services-div'>
          <img src={Driver} alt="" />
          <p>In the constant control of the bases, the drivers will ensure that you reach your destination on time and above all safely!</p>
        </div>
        <Link data-aos="fade-up" data-aos-duration="1000" to="/en/services">
          <span>More.</span>
        </Link>
      </div>
    </div>
  )
}

export default ServicesSection