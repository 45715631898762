import React from 'react'
import "./Questions.css"
import Foto from "./Foto.png"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const Questions = () => {
    return (
        <div className='q-section'>
            <h1>Pyetje</h1>
            <div className="q-div">
                <div className="q-accordion-section">
                    <div class="tab" data-aos="fade-up" data-aos-duration="1000">
                        <input id="tab-one" type="checkbox" name="tabs" />
                        <label for="tab-one">Në cilin qytet operoni?</label>
                        <div class="tab-content">
                            <p>Synimi i Taxi Beka që nga fillimet e para ka qenë afrimi i çdo cepi të qytetit të Pejës. Me kalimin e kohës kemi arritur të përfundojmë udhëtime përgjatë gjithë Ballkanit.</p>
                        </div>
                    </div>
                    <div class="tab" data-aos="fade-up" data-aos-duration="1500">
                        <input id="tab-two" type="checkbox" name="tabs" />
                        <label for="tab-two">Sa vonohet taxi?</label>
                        <div class="tab-content">
                            <p>Duke u bazuar në limitet rrugore ne shpejtojmë që të vijmë tek ju, por siguria është parësore. Me anë të bazitëve tonë profesionalë, ne u tregojmë kohën optimale brenda të cilës vozitëzi gjendet tek ju.</p>
                        </div>
                    </div>
                    <div class="tab" data-aos="fade-up" data-aos-duration="2000">
                        <input id="tab-three" type="checkbox" name="tabs" />
                        <label for="tab-three">Pse të udhëtojmë me ju?</label>
                        <div class="tab-content">
                            <p>Eksperienca që kemi fituar përgjatë 18 viteve punë na bënë ne zgjidhjen primare kur bie puna tek taksitë. Siguria, komoditeti dhe qetësia gjatë udhëtimit bën që ne të jemi të veçantë. Si gjithmonë, eksperienca bën diferencën.</p>
                        </div>
                    </div>
                </div>
                <div className="q-img-section">
                    <img data-aos="fade-up" data-aos-duration="1000" src={Foto} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Questions