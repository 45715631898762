import React from 'react'
import "./ReserveTaxiForm.css"

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const ReserveTaxiForm = () => {
    return (
        <div className='r-section'>
            <h1 data-aos="fade-up" data-aos-duration="1000">Rezervo Taxi</h1>
            <p data-aos="fade-up" data-aos-duration="1000">Ju lutem plotësoni fushat më poshtë për të rezervuar veturën tuaj. Ne do iu kontaktojmë për të verifikuar rezervimin!</p>

            <div data-aos="fade-up" data-aos-duration="1000" className="r-questions">
                <form action="https://formsubmit.co/taxibeka5@gmail.com" method="POST">
                    <input type="hidden" name="_next" value="https://taxibeka.com/mesazhi-sukses" />
                    <input type="hidden" name="_subject" value="Rezervim i Taxit!" />

                    <div className="r-question-input">
                        <label for="emridhembiemri">Emri dhe Mbiemri:</label>
                        <input type="text" id="emridhembiemri" required minLength={5} autoComplete="off" placeholder='Filan Fisteku' name="Emri-dhe-Mbiemri" />
                    </div>

                    <div className="r-question-input">
                        <label for="numritelefonit">Numri telefonit:</label>
                        <input type="text" id="numritelefonit" required minLength={5} autoComplete="off" name="Numri-Telefonit" placeholder='044-123-456' />
                    </div>

                    <div className="r-question-input">
                        <label for="datarezervimit">Data e rezervimit:</label>
                        <input type="date" id="datarezervimit" required minLength={3} autoComplete="off" name="Data-e-rezervimit-(V-M-D)" />
                    </div>

                    <div className="r-question-input">
                        <label for="orarezervimit">Ora e rezervimit:</label>
                        <input type="text" id="orarezervimit" required autoComplete="off" name='Ora-rezervimit' placeholder='10:00' />
                    </div>

                    <div className="r-question-input">
                        <label for="adresataksi">Adresa ku të dërgohet taxi:</label>
                        <input type="text" id="adresataksi" autoComplete="off" name='Adresa-ku-te-dergohet-taxi' placeholder='Nëna Terezë' />
                    </div>

                    <div className="r-question-input">
                        <label for="qyteti">Qyteti:</label>
                        <input type="text" id="qyteti" autoComplete="off" name='Qyteti' placeholder='Pejë' />
                    </div>

                    <div className="r-question-input">
                        <label for="destinacionifinal">Destinacioni final:</label>
                        <input type="text" id="destinacionifinal" required autoComplete="off" name='Destinacioni-final' placeholder='Prishtinë' />
                    </div>

                    <div className="r-question-input">
                        <label for="informata-shtese">Informata shtesë (opsionale):</label>
                        <textarea name="Informata-shtese" id="informata-shtese" cols="30" rows="5" placeholder='Dua që...'></textarea>
                    </div>

                    <button type='submit' id="Dergo">Dërgo</button>

                </form>
            </div>
        </div>
    )
}

export default ReserveTaxiForm