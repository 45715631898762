import React from 'react'
import "./PrinciplesAndClients.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

// Animate on Scroll library
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use a CSS file

AOS.init();

const PrinciplesAndClients = () => {
    return (
        <div className='pac-section'>
            <div className="principles-section">
                <h1 data-aos="fade-up" data-aos-duration="1000"><span>Parimet</span> Tona</h1>
                <div className='principles-div'>
                    <div className='principles'>
                        <h4 data-aos="fade-up" data-aos-duration="2000">1</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Vetura moderne</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">2</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Vetura të pastërta</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">3</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Shërbim profesional</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">4</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Staf me përvojë</p>
                    </div>
                    <div className='principles' data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos="fade-up" data-aos-duration="2000">5</h4>
                        <p data-aos="fade-up" data-aos-duration="2500">Të saktë</p>
                    </div>
                </div>
            </div>
            <div className="clients-section">
                <h1 data-aos="fade-up" data-aos-duration="1000"><span>Flasin</span> për ne</h1>
                <div data-aos="fade-up" data-aos-duration="1000">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>1</h3>
                                <p>"Shum te mire dhe te sakt respekt per punen qe beni"</p>
                                <h5>- Pasagjerë</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>2</h3>
                                <p>"Jeni per qdo lavdate me pasagjere ju lumte per punen qe e beni"</p>
                                <h5>- Pasagjerë</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>3</h3>
                                <p>"Pershendetje nga Gjermania une ne Kosov kom udhtu edhe jom shum e knaqme me juve
                                    jeni kompania ma e mira"</p>
                                <h5>- Pasagjerë</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>4</h3>
                                <p>"Pershendetje nga Parisi dy muj me ju ishit mrekulli une jom shpesh ne kosov jeni
                                    mrekulli pra ju pershendes te gjithve kem kalu mir me ju"</p>
                                <h5>- Pasagjerë</h5>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="client-square">
                                <h3>5</h3>
                                <p>"Taksi ma i miri ne qytetin e Pejes Respekt"</p>
                                <h5>- Pasagjerë</h5>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default PrinciplesAndClients