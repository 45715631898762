import React from 'react'
import "./Footer.css"
import LogoFooter from "./FooterLogo.webp"
import Logo from "./Logo.webp"
import { BsFacebook, BsWhatsapp, BsInstagram } from "react-icons/bs"
import { FaViber } from "react-icons/fa"
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const Footer = () => {

  const [pNderLogo, setPNderLogo] = useState();
  const [kontakt, setKontakt] = useState();
  const [ndiqni, setNdiqni] = useState();
  const [krijuar, setKrijuar] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/en') {
      setPNderLogo('A ride to remember!');
      setKontakt('Contact Us!');
      setNdiqni('Follow Us!');
      setKrijuar('Crafted by:');
    } else if (location.pathname === '/en/services') {
      setPNderLogo('A ride to remember!');
      setKontakt('Contact Us!');
      setNdiqni('Follow Us!');
      setKrijuar('Crafted by:');
    } else if (location.pathname === '/en/about-us') {
      setPNderLogo('A ride to remember!');
      setKontakt('Contact Us!');
      setNdiqni('Follow Us!');
      setKrijuar('Crafted by:');
    } else {
      setPNderLogo("Udhëtim për t'u kujtuar!")
      setKontakt('Na Kontaktoni!');
      setNdiqni('Na Ndiqni!');
      setKrijuar('Krijuar nga:');
    }
  }, [location]);

  return (
    <div className="f-background">
      <img className='logo-part' src={LogoFooter} alt="" />
      <div className="f-section">
        <div className="f-logo-section">
          <img src={Logo} alt="" />
          <p>{pNderLogo}</p>
        </div>
        <div className="f-contact">
          <h3>{kontakt}</h3>
          <p>Mbretëresha Teutë, Pejë</p>
          <p>+383 49 327 286</p>
          <p>taxibeka5@gmail.com</p>
        </div>
        <div className="f-socials">
          <h3>{ndiqni}</h3>
          <a href="https://www.facebook.com/RadioTaxiBeka" target="_blank">
            <BsFacebook className='f-icons' />
          </a>
          <a href="https://www.instagram.com/radiotaxibeka/?hl=en" target="_blank">
            <BsInstagram className='f-icons' />
          </a>
          <a href="https://api.whatsapp.com/send?phone=049327286" target="_blank">
            <BsWhatsapp className='f-icons' />
          </a>
          <a href="viber://chat/?number=%2B38349327286" target="_blank">
            <FaViber className='f-icons' />
          </a>
        </div>
      </div>
      <div className="f-subfooter">
        <p>Copyright © 2023 Taxi Beka.</p>
        <p>{krijuar} <a href="https://blert.netlify.app">Blert</a></p>
      </div>
    </div >
  )
}

export default Footer